import React from "react";
import styled from "styled-components";

// @ts-ignore
import heroImage from "../assets/hero-image.webp";

const HeroContainer = styled.div`
    background-image: url(${heroImage});
    background-size: cover;
    background-position: center;
    color: white;
    text-align: center;
    padding: 100px 20px;
`;

const HeroTitle = styled.h1`
    font-size: 2.5rem;
    margin-bottom: 20px;
`;

const HeroSubtitle = styled.p`
    font-size: 1.25rem;
    margin-bottom: 30px;
`;

const HeroCTA = styled.button`
    padding: 10px 30px;
    background-color: ${(props) => props.theme.colors.royalBlue};
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    &:hover {
        background-color: ${(props) => props.theme.colors.oxfordBlue};
    }
`;

const HeroSection = () => (
    <HeroContainer>
        <HeroTitle>Driving Positive Change with AI</HeroTitle>
        <HeroSubtitle>
            Pegasus Innovations is dedicated to fostering AI startups aimed at
            solving critical problems for the greater good.
        </HeroSubtitle>
        <HeroCTA>Join Us</HeroCTA>
    </HeroContainer>
);

export default HeroSection;
