const theme = {
    colors: {
        white: "#FFFFFF",
        glitter: "#E6E8FA",
        oxfordBlue: "#002147",
        paynesGrey: "#536878",
        royalBlue: "#4169E1",
    },
};

export default theme;
