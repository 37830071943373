import React from "react";
import styled from "styled-components";
// @ts-ignore
import missionImage from "../assets/mission-image.webp";

const SectionContainer = styled.div`
    display: flex;
    align-items: center;
    padding: 50px 20px;
    gap: 20px;
`;

const Image = styled.img`
    width: 50%;
    border-radius: 8px;
`;

const Content = styled.div`
    width: 50%;
`;

const Title = styled.h2`
    color: ${(props) => props.theme.colors.oxfordBlue};
`;

const Text = styled.p`
    line-height: 1.6;
`;

const OurMissionSection = () => (
    <SectionContainer>
        <Image src={missionImage} alt="Our Mission" />
        <Content>
            <Title>Our Mission</Title>
            <Text>
                Pegasus Innovations is dedicated to fostering AI startups aimed
                at solving critical problems for the greater good. Our deep
                industry insights and focus on impactful solutions position us
                as a catalyst for positive change.
            </Text>
        </Content>
    </SectionContainer>
);

export default OurMissionSection;
