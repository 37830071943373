// src/App.tsx
import React from "react";
import styled from "styled-components";
import theme from "./theme";
import Header from "./components/Header";
import HeroSection from "./components/HeroSection";
import OurMissionSection from "./components/OurMissionSection";
import { ThemeProvider } from "styled-components";

const MainContainer = styled.div`
    background-color: ${theme.colors.white};
    color: ${theme.colors.oxfordBlue};
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
`;

const Section = styled.section`
    padding: 60px 20px;
    &:nth-child(even) {
        background-color: ${theme.colors.glitter};
    }
`;

const Title = styled.h2`
    color: ${theme.colors.royalBlue};
    text-align: center;
`;

const Content = styled.p`
    text-align: justify;
    margin: 20px auto;
    max-width: 800px;
`;

function App() {
    return (
        <ThemeProvider theme={theme}>
            <MainContainer>
                {/* <Header /> */}
                <HeroSection />
                <OurMissionSection />
                <Section>
                    <Title>Our Mission</Title>
                    <Content>
                        Pegasus Innovations is dedicated to fostering AI
                        startups aimed at solving critical problems for the
                        greater good. Our deep industry insights and focus on
                        impactful solutions position us as a catalyst for
                        positive change.
                    </Content>
                </Section>
                <Section>
                    <Title>Why Choose Us?</Title>
                    <Content>
                        Expertise-Driven: Our team's industry experience ensures
                        our startups are perfectly poised for success.
                        Solution-Focused: We prioritize meaningful AI solutions
                        that address real-world challenges. Strategic
                        Partnerships: Our network includes leading VCs and
                        industry experts, offering startups unparalleled support
                        and resources.
                    </Content>
                </Section>
                <Section>
                    <Title>Our Approach</Title>
                    <Content>
                        Flexible Incubation: We adapt to your startup's unique
                        needs, offering tailored support, whether through
                        capital, bootstrapping, or a hybrid model. Commitment to
                        Impact: We expect vision and offer in return our full
                        support to achieve rapid, sustainable growth. Equitable
                        Collaboration: Fair equity distribution reflects the
                        value brought by founders and innovators within our
                        ecosystem.
                    </Content>
                </Section>
                <Section>
                    <Title>Join the Movement</Title>
                    <Content>
                        If you're passionate about leveraging AI for good, we
                        want you. Connect with Pegasus Innovations — where your
                        ideas can soar and create lasting impact.
                    </Content>
                </Section>
            </MainContainer>
        </ThemeProvider>
    );
}

export default App;
